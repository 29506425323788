/* GenerateLink.css */

.generate-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #7d8002, #ca0822, #06088b);
}

.generate-link-form {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px;
}

.generate-link-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #154add;
}

.generate-link-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

button {
    width: 100%;
    padding: 10px;
    background-color:rgb(112, 16, 99);
    color: #ffffff;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #e366ee;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}

textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical; /* Allow vertical resizing */
}

/* Optional: Add some responsive styles */
@media (max-width: 400px) {
    .generate-link-form {
        width: 90%;
        padding: 20px;
    }
}